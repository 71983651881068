import React, { useState, useEffect, useRef } from 'react';
import Parse from 'parse/dist/parse.min.js';
import { Form, Input, Button, notification } from 'antd';
import { Loader } from "@googlemaps/js-api-loader"; 


/* global google */



export const NewSurfBreak = () => {
  const [name, setName] = useState('');
  const [location, setLocation] = useState(null);
  const [notes, setNotes] = useState('');
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  

  useEffect(() => {
    const handleLocationError = (error) => {
      // Default location if error or no geolocation
      const defaultLocation = { lat: -34.397, lng: 150.644 };
      console.warn(`ERROR(${error.code}): ${error.message}`);
      setLocation(defaultLocation);
      loadMap(defaultLocation); // Load map with default location
    };

    const loadMap = (location) => {
      const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        version: "weekly",
      });

      loader.load().then(() => {
        const map = new google.maps.Map(mapRef.current, {
          center: location,
          zoom: 12,
        });

        markerRef.current = new google.maps.Marker({
          position: location,
          map: map,
        });

        map.addListener("click", (e) => {
          const lat = e.latLng.lat();
          const lng = e.latLng.lng();
          setLocation({ lat, lng });
          markerRef.current.setPosition(new google.maps.LatLng(lat, lng));
        });
      });
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setLocation(currentLocation);
          loadMap(currentLocation); // Load map with user's location
        },
        handleLocationError,
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
      handleLocationError({
        code: 0,
        message: "Geolocation not supported"
      });
    }
  }, []);


  const handleSubmit = async () => {
    
    const currentUser = Parse.User.current(); // Get the current logged-in user
        if (!currentUser) {
            notification.error({ message: 'Error', description: 'No user logged in.' });
            return; // Exit if there's no logged-in user
        }

    const SurfBreak = Parse.Object.extend("SurfBreak");
    const surfBreak = new SurfBreak();

    // Create a Parse.GeoPoint with the latitude and longitude
    const geoPoint = new Parse.GeoPoint({
      latitude: location.lat,
      longitude: location.lng
    });

    surfBreak.set("break_name", name);
    surfBreak.set("location", geoPoint);
    surfBreak.set("notes", notes);
    surfBreak.set("user", currentUser); // Associate the current user with the surf break

    try {
      await surfBreak.save();
      notification.success({ message: 'Surf Break Added', description: 'The surf break has been successfully added.' });
      // Optionally clear the form
      setName('');
      setLocation('');
      setNotes('');
    } catch (error) {
      notification.error({ message: 'Error', description: error.message });
    }
  };



  return (
    <Form 
      name="newsurfbreak"
      layout="vertical" 
      onFinish={handleSubmit}
      className="responsive-form"
      style={{
        maxWidth: 600,
        marginLeft: '2%',
        marginRight: '2%',
        marginTop: '2%',
        marginBottom: '50px'
      }}
      
      >
      

      <Form.Item >
        <Input placeholder="Surf Break Name" value={name} onChange={(e) => setName(e.target.value)} />
      </Form.Item>
      <Form.Item label="Location">
        <div ref={mapRef} style={{ height: "400px", width: "100%" }}></div>
      </Form.Item>
      <Form.Item >
        <Input.TextArea placeholder="Notes" value={notes} onChange={(e) => setNotes(e.target.value)} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginBottom: '50px' }}>
          Add Surf Break
        </Button>
      </Form.Item>
    </Form>
  );
};
