import React, { useState } from 'react';
import Parse from 'parse/dist/parse.min.js';
import {
    Alert,
    Button,
    Form,
    Input,
    notification,
    Spin
  } from "antd";


const Subscribe = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const Subscriber = Parse.Object.extend('Subscriber');
    const subscriber = new Subscriber();

    subscriber.set('name', name);
    subscriber.set('email', email);

    try {
      await subscriber.save();
      notification.success({
        message: 'You got it!',
        description: 'You\'ll get the next update.',
      });
    } catch (error) {
        notification.error({
            message: 'Error',
            description: error.message,
          });
    }

    setName('');
    setEmail('');
  };

  return (
   <div className="subscribe-container"> 
    <div className="subscribe">
      <form 
        onSubmit={handleSubmit}
        className="subscribe-form"
        layout="vertical"
        autoComplete="off"
        >
        
        <Form.Item className="form-item">
            <Input.TextArea 
                placeholder="Name" 
                value={name} 
                onChange={(e) => setName(e.target.value)} 
                required
            />
        </Form.Item>
        <Form.Item className="form-item">
            <Input.TextArea 
                placeholder="Email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required
            />
        </Form.Item>
        <Form.Item className="form-item button-item" style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit">
                Send it
            </Button>
        </Form.Item>
      </form>
      
    </div>
    </div> 
  );
};

export default Subscribe;
