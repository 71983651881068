import moment from 'moment';
import Parse from 'parse';



//Convert degrees into compass direction
export const getCompassDirection = (angle) => {
    const directions = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
    const index = Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 22.5) % 16;
    return directions[index];
};

//Calculate distance between two points when you have lat lng
export const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Earth’s radius in kilometers
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = 
      0.5 - Math.cos(dLat)/2 + 
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      (1 - Math.cos(dLon)) / 2;
  
    return R * 2 * Math.asin(Math.sqrt(a));
  };

  //TIME FUNCTIONS

  //Format time from minutes to hours and minutes
  export const formatMinutesTime = (minutes) => {
    const hrs = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hrs}:${mins < 10 ? '0' : ''}${mins}`;
  };
  
  //Convert time - rounding to hours
  export const processSunTimes = (time) => {
    const originalTime = moment(time);
    const roundedTime = originalTime.clone().startOf('hour'); // Cloning to avoid mutating the original moment
  
    return {
      originalTime,
      roundedTime,
    };
  };

  //Format time to 1pm, 2pm, etc.
  export const formatTime = (time) => {
    const [hour, minute] = time.split(':');
    const hourInt = parseInt(hour, 10);
    const suffix = hourInt >= 12 ? 'pm' : 'am';
    const formattedHour = hourInt % 12 || 12;  // Convert "00" to "12" for 12 AM
    return `${formattedHour}${suffix}`;
  }

  export const getArrow = (direction) => {
    const arrows = {
      'N': '↓',  // North coming from, arrow points South
      'NNE': '↙', // North-Northeast coming from, arrow points South-Southwest
      'NE': '↙',  // Northeast coming from, arrow points Southwest
      'ENE': '↙', // East-Northeast coming from, arrow points West-Southwest
      'E': '←',  // East coming from, arrow points West
      'ESE': '↖', // East-Southeast coming from, arrow points West-Northwest
      'SE': '↖',  // Southeast coming from, arrow points Northwest
      'SSE': '↖', // South-Southeast coming from, arrow points North-Northwest
      'S': '↑',  // South coming from, arrow points North
      'SSW': '↗', // South-Southwest coming from, arrow points North-Northeast
      'SW': '↗',  // Southwest coming from, arrow points Northeast
      'WSW': '↗', // West-Southwest coming from, arrow points East-Northeast
      'W': '→',  // West coming from, arrow points East
      'WNW': '↘', // West-Northwest coming from, arrow points East-Southeast
      'NW': '↘', // Northwest coming from, arrow points Southeast
      'NNW': '↘' // North-Northwest coming from, arrow points Southeast
    };
    return arrows[direction] || '';
  }
  

  // Wind Speed Coloring
  
  // Function to interpolate between two colors
const interpolateColor = (color1, color2, factor) => {
  const result = color1.slice();
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return `rgb(${result.join(",")})`;
};

// Function to convert hex to RGB array
const hexToRgb = (hex) => {
  const bigint = parseInt(hex.slice(1), 16);
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
};

// Colors for gradient
const lowWindColor = hexToRgb("#a0db8e"); // Light green
const mediumWindColor = hexToRgb("#def78a"); // Amber
const highWindColor = hexToRgb("#f25c54"); // Red

// Function to get wind speed color
export const getWindSpeedColor = (speed) => {
  if (speed < 5.5) {
    const factor = speed / 5.5;
    return interpolateColor(lowWindColor, mediumWindColor, factor);
  } else if (speed >= 5.5 && speed < 9) {
    const factor = (speed - 5.5) / (9 - 5.5);
    return interpolateColor(mediumWindColor, highWindColor, factor);
  } else {
    return `rgb(${highWindColor.join(",")})`;
  }
};


// Check if Parse is initialized. Used to fix Feedback form issues. 

export const isParseInitialized = () => {
  const Parse = require('parse');
  return Parse.applicationId && Parse.javaScriptKey;
};



