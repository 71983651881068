import React from 'react';
import NewBoard from '../Components/NewBoard'; 

const AddBoard = () => {
  const handleBoardAdded = (newBoard) => {
    // Handle the newly added board
    console.log('Board added:', newBoard);
    // Additional logic can be added here
  };

  return (
    <div className="content">
      <div className="title-card">
        <h2>Add New Surf Board</h2>
      </div>

      <NewBoard onBoardAdded={handleBoardAdded} />
    </div>
  );
};

export default AddBoard;