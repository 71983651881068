import React, { useState, useEffect } from 'react';
import './WeatherGrid.css';
import { getWindSpeedColor, formatTime, getArrow } from '../Utils';
import TideChart from './TideChart';
import moment from 'moment';


const WeatherGrid = ({ weatherData, tideData, date, sunriseTime, sunsetTime, startTime, endTime }) => {
    const { Time, AirTemp, WaveDirection, WaveHeight, WavePeriod, WindDirection, WindSpeed } = weatherData;

    
    
    function roundToTenth(value) {
        return Number(value).toFixed(1);
    }

    function isNightTime(dateTime, sunriseTime, sunsetTime) {
      if (!dateTime || !sunriseTime || !sunsetTime) {
          console.error('One or more time inputs are undefined.');
          return false;
      }
      const timeHour = parseInt(moment(dateTime, 'YYYY-MM-DD HH:mm').format('HH'), 10);
      const sunriseHour = parseInt(moment(sunriseTime, 'HH:mm').format('HH'), 10);
      const sunsetHour = parseInt(moment(sunsetTime, 'HH:mm').format('HH'), 10);
      return timeHour < sunriseHour || timeHour > sunsetHour;
  }

  function isSurfTime(dateTime, sessionDate, startTime, endTime) {
    if (!dateTime || !sessionDate || !startTime || !endTime) {
        console.error('One or more time inputs are undefined.');
        return false;
    }
    const sessionStart = moment(`${sessionDate} ${startTime}`, 'MMM D, YYYY HH:mm');
    const sessionEnd = moment(`${sessionDate} ${endTime}`, 'MMM D, YYYY HH:mm');
    const weatherPointTime = moment(dateTime, 'YYYY-MM-DD HH:mm');

    return weatherPointTime.isSameOrAfter(sessionStart) && weatherPointTime.isSameOrBefore(sessionEnd);
  }

    function getNextDay(dateString) {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    }

    return (
        <div className="weather-grid">
            <table>
                <thead>
                    <tr>
                        <th colSpan={Time.length + 1} className="date-header">{getNextDay(date)}</th>
                    </tr>
                    <tr>
                    <th className="weather-label">Date & Time</th>
                    {Time.map((dateTime, index) => (
                        <th
                            key={index}
                            className={`weather-cell ${isNightTime(dateTime, sunriseTime, sunsetTime) ? 'night-time' : ''} ${isSurfTime(dateTime, date, startTime, endTime) ? 'surf-time' : ''}`}>
                            {moment(dateTime).format('hA')}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                    {WaveDirection && (
                        <tr>
                            <th className="weather-label">Wave Direction</th>
                            {WaveDirection.map((wavedirection, index) => (
                                <td key={index} className={`weather-cell ${isSurfTime(Time[index], date, startTime, endTime) ? 'surf-time' : ''}`}>
                                    <div className="wave-direction">
                                        <span className="wave-arrow">{getArrow(wavedirection)}</span>
                                        {wavedirection}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    )}
                    {WaveHeight && (
                        <tr>
                            <th className="weather-label">Wave Height (m)</th>
                            {WaveHeight.map((waveheight, index) => (
                                <td key={index} className={`weather-cell ${isSurfTime(Time[index], date, startTime, endTime) ? 'surf-time' : ''}`}>
                                    <div className="wave-height-bar" style={{ height: `${waveheight * 20}px` }}>
                                        <span className="wave-height-label">{waveheight.toFixed(1)}</span>
                                    </div>
                                </td>
                            ))}
                        </tr>
                    )}
                    {WavePeriod && (
                        <tr>
                            <th className="weather-label">Wave Period (s)</th>
                            {WavePeriod.map((waveperiod, index) => (
                                <td key={index} className={`weather-cell ${isSurfTime(Time[index], date, startTime, endTime) ? 'surf-time' : ''}`}>{roundToTenth(waveperiod)}</td>
                            ))}
                        </tr>
                    )}
                    {WindDirection && (
                        <tr>
                            <th className="weather-label">Wind Direction</th>
                            {WindDirection.map((winddirection, index) => (
                                <td key={index} className={`weather-cell ${isSurfTime(Time[index], date, startTime, endTime) ? 'surf-time' : ''}`}>
                                    <div className="wind-direction">
                                        <span className="wind-arrow">{getArrow(winddirection)}</span>
                                        {winddirection}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    )}
                    {WindSpeed && (
                        <tr>
                            <th className="weather-label">Wind Speed (m/s)</th>
                            {WindSpeed.map((windspeed, index) => (
                                <td
                                    key={index}
                                    className={`weather-cell ${getWindSpeedColor(windspeed)}`}
                                    style={{ backgroundColor: getWindSpeedColor(windspeed) }}
                                >
                                    {roundToTenth(windspeed)}
                                </td>
                            ))}
                        </tr>
                    )}
                    <tr>
                        <th className="weather-label-tide">Tide (m)(MSL)</th>
                        <td colSpan={Time.length + 1} className={'tide-cell'}>
                            <TideChart
                                tideData={tideData}
                                date={date}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default WeatherGrid;
