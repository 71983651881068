
    import React, { useEffect, useState } from 'react';
    import { useNavigate } from 'react-router-dom';
    import Parse from 'parse/dist/parse.min.js';
    import { Table, Spin } from 'antd';
    import moment from 'moment';
    import { Link } from 'react-router-dom';
    import './TableStyles.css';
    import { formatMinutesTime } from '../Utils';

    const getRatingClass = (rating) => {
      if (rating <= 1) return 'rating-red';
      if (rating <= 2) return 'rating-yellow';
      if (rating <= 3) return 'rating-blue';
      if (rating <= 4) return 'rating-lightgreen';
      return 'rating-darkgreen';
    };

    export const MySessions = () => {
      const [sessions, setSessions] = useState([]); // State to hold the list of sessions
      const [isLoadingSessions, setIsLoadingSessions] = useState(false);


      useEffect(() => {
        setIsLoadingSessions(true);
        // Function to fetch sessions from Parse
        const fetchSessions = async () => {
          const currentUser = Parse.User.current(); // Get the currently logged-in user
          if (!currentUser) {
            console.log('No user logged in');
            setIsLoadingSessions(false);
            return;
          }
    
          const Surfsession = Parse.Object.extend('surfsession');
          const query = new Parse.Query(Surfsession);
          query.equalTo('user', currentUser); // Find sessions where 'user' field is the current user
          query.include('surfBreak'); //Pointer field name from surfsessions class
          query.descending('surf_session_date'); // Add this line before executing the query
         
          try {
            const results = await query.find();
            // Transform results to match Table data source format
            const formattedSessions = results.map((session) => ({
              key: session.id, // Important: each row needs a unique key
              date: moment(session.get('surf_session_date')).format('MMM D, YYYY'), // Adjusting format
              rating: session.get('surf_session_rating'),
              breakName: session.get('surfBreak') ? session.get('surfBreak').get('break_name') : 'Unknown',
              length: session.get('surf_session_duration'),
              notes: session.get('surf_session_notes'),
            }));
            
            setSessions(formattedSessions);
            
          } catch (error) {
            console.error('Error fetching surf sessions:', error.message);
          } finally {
            setIsLoadingSessions(false);
          }
        };
        
        
        fetchSessions(); // Call the fetch function
      }, []); // Empty dependency array means this effect runs once on component mount
    
      //Setup columns for table (AntD Format)
      const columns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
         // sorter: (a, b) => a.date - b.date,
          sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
          sortDirections: ['descend', 'ascend'], // Default to most recent dates first
          defaultSortOrder: 'descend', // Start with most recent dates
          render: (date) => <span className="no-wrap">{date}</span>,
        },
        {
          title: 'Rating',
          dataIndex: 'rating',
          key: 'rating',
          align: 'center',
          sorter: (a, b) => a.rating - b.rating,
          sortDirections: ['descend', 'ascend'],
          render: (rating) => (
            <div className="rating-container">
              <div className={`rating-bar ${getRatingClass(rating)}`} />
              {rating}
            </div>
          ),
        },
        {
          title: 'Break',
          dataIndex: 'breakName',
          key: 'breakName',
        },
        {
          title: 'Length',
          dataIndex: 'length',
          key: 'length',
          align: 'center',
          render: (length) => <span className="no-wrap">{formatMinutesTime(length)}</span>,
        },
        {
          title: 'Notes',
          dataIndex: 'notes',
          key: 'notes',
        },
      ];


      const navigate = useNavigate(); // Hook to navigate programmatically

      const onRowClick = (record) => ({
        onClick: () => {
          navigate(`/mysessions/${record.key}`); // Navigate to session details page
        },
      });


      

      return (
        
        <div className="content">
          <div className="title-card">
          <h2>My Surf Sessions</h2>
          </div>
            <Table 
              columns={columns} 
              dataSource={sessions} 
              onRow={onRowClick} 
              rowClassName={() => 'card-row'}
              loading={{
                spinning: isLoadingSessions,
                tip: 'Fetching your sessions...',
                indicator: <Spin size="large" /> 
              }}
              locale={{
                emptyText: isLoadingSessions ? ' ' : <div>You haven't logged any sessions yet, <Link to="/newsession">click here</Link> to log your first session.</div>
              }}
            />
        </div>
      );
    
    };
    
   
    