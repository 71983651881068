import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Input, Select, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Parse from 'parse';
import './Feedback.css';

const { Option } = Select;

const Feedback = () => {
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState('bug');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [image, setImage] = useState(null);
  const [username, setUsername] = useState('nouser');

  useEffect(() => {
    const initializeParse = () => {
      const app_id = process.env.REACT_APP_PARSE_APP_ID;
      const host_url = process.env.REACT_APP_PARSE_HOST_URL;
      const javascript_key = process.env.REACT_APP_PARSE_JAVASCRIPT_KEY;

      if (app_id && host_url && javascript_key) {
        Parse.initialize(app_id, javascript_key);
        Parse.serverURL = host_url;
        console.log("Parse initialized successfully with app_id:", app_id);
      } else {
        console.error("Parse initialization failed due to missing environment variables");
      }
    };

    initializeParse();

    const isInitialized = Parse.applicationId !== undefined && Parse.javaScriptKey !== undefined;
    console.log("Parse Initialized:", isInitialized);

    if (isInitialized) {
      const currentUser = Parse.User.current();
      if (currentUser) {
        setUsername(currentUser.getUsername());
      }
    } else {
      console.error("Parse is not initialized in Feedback component");
    }
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSubmit = async (values) => {
    const Feedback = Parse.Object.extend('Feedback');
    const feedback = new Feedback();

    feedback.set('type', values.type);
    feedback.set('description', values.description);
    feedback.set('email', values.email);
    feedback.set('url', window.location.href);
    feedback.set('username', username);

    if (image) {
      const file = new Parse.File(image.name, image);
      feedback.set('image', file);
    }

    try {
      await feedback.save();
      message.success('Your feedback has been submitted. Thank you!');
      toggleModal();
    } catch (error) {
      message.error('Failed to submit feedback: ' + error.message);
    }
  };

  const handleImageUpload = (file) => {
    setImage(file);
    return false; // Prevent default upload behavior
  };

  return (
    <div>
      <button className="feedback-button" onClick={toggleModal}>?</button>
      <Modal
        title={
            <div style={{ textAlign: 'center' }}>
              <div style={{ fontSize: '24px' }}>Submit Feedback</div>
              <div style={{ fontSize: '12px', color: '#888' }}>Thank you for helping us improve the app!</div>
            </div>
          }
        visible={showModal}
        onCancel={toggleModal}
        footer={null}
      >
        <Form onFinish={handleSubmit}>
          <Form.Item
            name="type"
            initialValue="bug"
            rules={[{ required: true, message: 'Please select a type!' }]}
          >
            <Select placeholder="Select Type" onChange={(value) => setType(value)}>
              <Option value="bug">Bug</Option>
              <Option value="feature">Feature Request</Option>
              <Option value="question">Question</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="description"
            rules={[{ required: true, message: 'Please enter a description!' }]}
          >
            <Input.TextArea placeholder="Description" onChange={(e) => setDescription(e.target.value)} />
          </Form.Item>
          <Form.Item
            
            name="email"
            rules={[{ required: true, message: 'Please enter your email!', type: 'email' }]}
          >
            <Input placeholder="Your Email" onChange={(e) => setEmail(e.target.value)} />
          </Form.Item>
          <Form.Item label="Screenshot (optional)">
            <Upload beforeUpload={handleImageUpload} showUploadList={false}>
              <Button icon={<UploadOutlined />} className="custom-upload-button">Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit">Submit</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Feedback;
