import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Parse from 'parse/dist/parse.min.js';
import moment from 'moment';
import { Modal } from 'antd';
import { fetchTideData, fetchWeatherData, fetchAstronomyData } from '../Components/WeatherData';
import TideChart from '../Components/TideChart';
import EditableNotes from '../Components/EditableNotes';
import WaveHeightChart from './WaveChart';
import WeatherGrid from './WeatherGrid';
import './SessionDetails.css';

const SessionDetails = () => {
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const [sessionDetails, setSessionDetails] = useState(null);
  const [tideData, setTideData] = useState(null);
  const [loadingTide, setLoadingTide] = useState(false);
  const [weatherData, setWeatherData] = useState(null);
  const [loadingWeather, setLoadingWeather] = useState(false);
  const [originalSunriseTime, setOriginalSunriseTime] = useState(null);
  const [originalSunsetTime, setOriginalSunsetTime] = useState(null);
  const [roundedSunriseTime, setRoundedSunriseTime] = useState(null);
  const [roundedSunsetTime, setRoundedSunsetTime] = useState(null);
  const [waveHeightStart, setWaveHeightStart] = useState(null);
  const [waveHeightEnd, setWaveHeightEnd] = useState(null);
  const [wavePeriodStart, setWavePeriodStart] = useState('Loading...');
  const [wavePeriodEnd, setWavePeriodEnd] = useState('Loading...');
  const [waveDirectionStart, setWaveDirectionStart] = useState('Loading...');
  const [waveDirectionEnd, setWaveDirectionEnd] = useState('Loading...');
  const [waveDirectionDegreesStart, setWaveDirectionDegreesStart] = useState('Loading...');
  const [waveDirectionDegreesEnd, setWaveDirectionDegreesEnd] = useState('Loading...');
  const [windSpeedStart, setWindSpeedStart] = useState('Loading...');
  const [windSpeedEnd, setWindSpeedEnd] = useState('Loading...');
  const [windDirectionStart, setWindDirectionStart] = useState('Loading...');
  const [windDirectionEnd, setWindDirectionEnd] = useState('Loading...');
  const [windDirectionDegreesStart, setWindDirectionDegreesStart] = useState('Loading...');
  const [windDirectionDegreesEnd, setWindDirectionDegreesEnd] = useState('Loading...');
  const [startHeight, setTideHeightStart] = useState('Loading...');
  const [endHeight, setTideHeightEnd] = useState('Loading...');
  const [isEditing, setIsEditing] = useState(false);
  const [newNotes, setNewNotes] = useState('');
  

  const marks = {
    1: 'Terrible',
    2: 'Not Great',
    3: 'Fun',
    4: 'Super Fun',
    5: 'Epic'
  };

  function roundToWhole(value) {
    return Number(value).toFixed();
  }

  useEffect(() => {
    const fetchSessionAndWeatherData = async () => {
      const Surfsession = Parse.Object.extend('surfsession');
      const query = new Parse.Query(Surfsession);
      query.equalTo('objectId', sessionId);
      query.include('surfBreak');
      query.include('surfBoard');

      try {
        const sessionObject = await query.first();
        if (sessionObject) {
          const breakObject = sessionObject.get('surfBreak');
          const location = breakObject ? breakObject.get('location') : null;
          const boardObject = sessionObject.get('surfBoard');

          const sessionInfo = {
            date: moment(sessionObject.get('surf_session_date')).format('MMM D, YYYY'),
            rawDate: moment(sessionObject.get('surf_session_date')).format('YYYY-MM-DD'),
            breakName: breakObject ? breakObject.get('break_name') : 'Unknown',
            length: sessionObject.get('surf_session_duration'),
            notes: sessionObject.get('surf_session_notes'),
            startTime: moment(sessionObject.get('surf_session_date')).format('HH:mm'),
            endTime: moment(sessionObject.get('surf_session_date_end')).format('HH:mm'),
            roundedStartTime: moment(sessionObject.get('surf_session_date')).startOf('hour').format('HH:mm'),
            roundedEndTime: moment(sessionObject.get('surf_session_date_end')).startOf('hour').format('HH:mm'),
            rating: sessionObject.get('surf_session_rating'),
            boardName: boardObject ? boardObject.get('board_name') : 'Unknown'
          };
          setSessionDetails(sessionInfo);
          

            //fetch weather and tide data
            if (location) {
              setLoadingTide(true);
              setLoadingWeather(true);
              
              const [tideResponse, weatherResponse, astronomyResponse] = await Promise.all([
                fetchTideData(location.latitude, location.longitude, sessionObject.get('surf_session_date')),
                fetchWeatherData(location.latitude, location.longitude, sessionObject.get('surf_session_date')),
                fetchAstronomyData(location.latitude, location.longitude, sessionObject.get('surf_session_date'))
               ]);

                setTideData(tideResponse);
                setWeatherData(weatherResponse);


               // Extract and set wave heights
               if (weatherResponse && weatherResponse.Time) {
                const waveHeightAtStart = getWeatherParameterAtTime(weatherResponse, sessionInfo.roundedStartTime, 'WaveHeight');
                const waveHeightAtEnd = getWeatherParameterAtTime(weatherResponse, sessionInfo.roundedEndTime, 'WaveHeight');
                setWaveHeightStart(waveHeightAtStart !== undefined ? waveHeightAtStart : 'N/A');
                setWaveHeightEnd(waveHeightAtEnd !== undefined ? waveHeightAtEnd : 'N/A');

                const wavePeriodAtStart = getWeatherParameterAtTime(weatherResponse, sessionInfo.roundedStartTime, 'WavePeriod');
                const wavePeriodAtEnd = getWeatherParameterAtTime(weatherResponse, sessionInfo.roundedEndTime, 'WavePeriod');
                setWavePeriodStart(wavePeriodAtStart !== undefined ? wavePeriodAtStart : 'N/A');
                setWavePeriodEnd(wavePeriodAtEnd !== undefined ? wavePeriodAtEnd : 'N/A');

                const waveDirectionAtStart = getWeatherParameterAtTime(weatherResponse, sessionInfo.roundedStartTime, 'WaveDirection');
                const waveDirectionAtEnd = getWeatherParameterAtTime(weatherResponse, sessionInfo.roundedEndTime, 'WaveDirection');
                setWaveDirectionStart(waveDirectionAtStart !== undefined ? waveDirectionAtStart : 'N/A');
                setWaveDirectionEnd(waveDirectionAtEnd !== undefined ? waveDirectionAtEnd : 'N/A');

                const waveDirectionDegreesAtStart = getWeatherParameterAtTime(weatherResponse, sessionInfo.roundedStartTime, 'WaveDirectionDegrees');
                const waveDirectionDegreesAtEnd = getWeatherParameterAtTime(weatherResponse, sessionInfo.roundedEndTime, 'WaveDirectionDegrees');
                setWaveDirectionDegreesStart(waveDirectionDegreesAtStart !== undefined ? waveDirectionDegreesAtStart : 'N/A');
                setWaveDirectionDegreesEnd(waveDirectionDegreesAtEnd !== undefined ? waveDirectionDegreesAtEnd : 'N/A');

                const windSpeedAtStart = getWeatherParameterAtTime(weatherResponse, sessionInfo.roundedStartTime, 'WindSpeed');
                const windSpeedAtEnd = getWeatherParameterAtTime(weatherResponse, sessionInfo.roundedEndTime, 'WindSpeed');
                setWindSpeedStart(windSpeedAtStart !== undefined ? windSpeedAtStart : 'N/A');
                setWindSpeedEnd(windSpeedAtEnd !== undefined ? windSpeedAtEnd : 'N/A');

                const windDirectionAtStart = getWeatherParameterAtTime(weatherResponse, sessionInfo.roundedStartTime, 'WindDirection');
                const windDirectionAtEnd = getWeatherParameterAtTime(weatherResponse, sessionInfo.roundedEndTime, 'WindDirection');
                setWindDirectionStart(windDirectionAtStart !== undefined ? windDirectionAtStart : 'N/A');
                setWindDirectionEnd(windDirectionAtEnd !== undefined ? windDirectionAtEnd : 'N/A');

                const windDirectionDegreesAtStart = getWeatherParameterAtTime(weatherResponse, sessionInfo.roundedStartTime, 'WindDirectionDegrees');
                const windDirectionDegreesAtEnd = getWeatherParameterAtTime(weatherResponse, sessionInfo.roundedEndTime, 'WindDirectionDegrees');
                setWindDirectionDegreesStart(windDirectionDegreesAtStart !== undefined ? windDirectionDegreesAtStart : 'N/A');
                setWindDirectionDegreesEnd(windDirectionDegreesAtEnd !== undefined ? windDirectionDegreesAtEnd : 'N/A');

                const { startHeight, endHeight } = interpolateTideHeights(tideResponse, sessionInfo.rawDate, sessionInfo.startTime, sessionInfo.endTime);
                setTideHeightStart(startHeight !== undefined ? startHeight.toFixed(2) : 'N/A');
                setTideHeightEnd(endHeight !== undefined ? endHeight.toFixed(2) : 'N/A');
                

              }


                
                if (astronomyResponse) {
                  setOriginalSunriseTime(astronomyResponse.originalSunriseTime);
                  setOriginalSunsetTime(astronomyResponse.originalSunsetTime);
                  setRoundedSunriseTime(astronomyResponse.roundedSunriseTime);
                  setRoundedSunsetTime(astronomyResponse.roundedSunsetTime);
                }
                
                setLoadingTide(false);
                setLoadingWeather(false);

            }
        }
      } catch (error) {
            console.error('Error fetching session details:', error.message);
          }
     };

    
    fetchSessionAndWeatherData();

  }, [sessionId]);
  

  const getWeatherParameterAtTime = (weatherData, targetTime, parameter) => {
    // Find the index where the time part matches the targetTime
    const timeIndex = weatherData.Time.findIndex(time => {
        // Extract the time part from the datetime string and compare it with targetTime
        const timePart = moment(time, 'YYYY-MM-DD HH:mm').format('HH:mm');
        return timePart === targetTime;
    });

    return timeIndex !== -1 ? weatherData[parameter][timeIndex] : undefined;
};

//INTERPOLATE TIDE HEIGHT

const interpolateTideHeights = (tideData, rawDate, startTime, endTime) => {
  const interpolate = (targetTime) => {
    const targetMoment = moment(`${rawDate} ${targetTime}`, 'YYYY-MM-DD HH:mm');
    let lowerPoint = null;
    let upperPoint = null;

    for (let i = 0; i < tideData.length; i++) {
      const point = tideData[i];
      const pointMoment = moment(point.time, 'YYYY-MM-DD HH:mm:ss');

      if (pointMoment.isSame(targetMoment)) {
        return parseFloat(point.height);
      }

      if (pointMoment.isBefore(targetMoment)) {
        lowerPoint = point;
      }

      if (pointMoment.isAfter(targetMoment) && !upperPoint) {
        upperPoint = point;
      }
    }

    if (lowerPoint && upperPoint) {
      const lowerTime = moment(lowerPoint.time, 'YYYY-MM-DD HH:mm:ss');
      const upperTime = moment(upperPoint.time, 'YYYY-MM-DD HH:mm:ss');

      const totalDuration = upperTime.diff(lowerTime);
      const elapsedDuration = targetMoment.diff(lowerTime);
      const omega = (Math.PI * elapsedDuration) / totalDuration;
      const interpolatedHeight = parseFloat(lowerPoint.height) +
        (parseFloat(upperPoint.height) - parseFloat(lowerPoint.height)) * (0.5 * (1 - Math.cos(omega)));

      return interpolatedHeight;
    }

    console.warn('Unable to interpolate tide height: Missing lower or upper point');
    return undefined;
  };

  const startHeight = interpolate(startTime);
  const endHeight = interpolate(endTime);

  return {
    startHeight,
    endHeight
  };
};

  
//HANDLE DELETE SESSION FUNCTIONALITY

const showDeleteConfirm = () => {
  Modal.confirm({
    title: 'Are you sure you want to delete this session?',
    content: 'This action cannot be undone.',
    okText: 'Delete',
    okType: 'danger',
    cancelText: 'Cancel',
    onOk: handleDelete,
    onCancel() {
      console.log('Cancel');
    },
  });
};

const handleDelete = async () => {
  const Surfsession = Parse.Object.extend('surfsession');
  const query = new Parse.Query(Surfsession);

  try {
    const sessionToDelete = await query.get(sessionId);
    await sessionToDelete.destroy();
    alert('Session deleted successfully!');
    // Redirect to the sessions list after deletion
    navigate('/mysessions');
  } catch (error) {
    console.error('Failed to delete the session', error);
    alert('Failed to delete the session');
  }
};
  



  if (!sessionDetails) {
    return <div>Loading...</div>;
  }

  const renderRating = (rating) => {
    const entries = Object.entries(marks);
    return entries.map(([key, value], index) => (
      <React.Fragment key={key}>
        <span style={{ fontWeight: rating === parseInt(key) ? 'bold' : 'normal', color: rating === parseInt(key) ? 'black' : 'lightgray' }}>
          {value}
        </span>
        {index < entries.length - 1 && <span style={{ color: 'lightgray' }}> {' > '} </span>}
      </React.Fragment>
    ));

    

  };

  const getRatingClass = (rating) => {
    switch (rating) {
      case 1: return 'rating-red';
      case 2: return 'rating-yellow';
      case 3: return 'rating-blue';
      case 4: return 'rating-lightgreen';
      case 5: return 'rating-darkgreen';
      default: return '';
    }
  };

  
  

  return (
    <div className="session-details">
      <div className="title-card-session-details">
        <div className={`rating-bar ${getRatingClass(sessionDetails.rating)}`}></div>
        <div className="title-content">
          <h2>{sessionDetails.breakName}</h2>
          <p>{sessionDetails.date}</p>
          <p>{renderRating(sessionDetails.rating)}</p>
        </div>
      </div>
      <div className="weather-grid-container">
        {loadingWeather || loadingTide ? <p>Loading data...</p> :
          weatherData ? (
            <WeatherGrid
              weatherData={weatherData}
              sunriseTime={roundedSunriseTime}
              sunsetTime={roundedSunsetTime}
              startTime={sessionDetails.roundedStartTime}
              endTime={sessionDetails.roundedEndTime}
              tideData={tideData}
              date={sessionDetails.date}
            />
          ) : <p>No weather data available.</p>
        }
      </div>
      <div className="title-card">
        <div className={`rating-bar ${getRatingClass(sessionDetails.rating)}`}></div>
        <div className="title-content">
          <h2>Session Details</h2>
          <p><b>Surf Time:</b> {sessionDetails.startTime} - {sessionDetails.endTime}</p>
          <p><b>Rating: </b>{renderRating(sessionDetails.rating)}</p>
          <p><b>Board: </b>{sessionDetails.boardName}</p>
        </div>
      </div>
      <div className="info-cards-container">
        <div className="info-card">
          <h3>Waves</h3>
          <p><b>Height:</b> {waveHeightStart} - {waveHeightEnd}m</p>
          <p><b>Period:</b> {wavePeriodStart} - {wavePeriodEnd}s</p>
          <p><b>Direction:</b> {waveDirectionStart} ({roundToWhole(waveDirectionDegreesStart)}°) - {waveDirectionEnd} ({roundToWhole(waveDirectionDegreesEnd)}°)</p>
        </div>
        <div className="info-card">
          <h3>Wind</h3>
          <p><b>Speed:</b> {windSpeedStart} - {windSpeedEnd}m/s</p>
          <p><b>Direction:</b> {windDirectionStart} ({roundToWhole(windDirectionDegreesStart)}°) - {windDirectionEnd} ({roundToWhole(windDirectionDegreesEnd)}°)</p>
        </div>
        <div className="info-card">
          <h3>Tide</h3>
          <p><b>Height:</b> {startHeight} - {endHeight}m</p>
          
        </div>
      </div>
        <div className="notes-card-container">
        <EditableNotes
          initialNotes={sessionDetails.notes}
          onSave={(newNotes) => {
           // Implement the save logic here
            const Surfsession = Parse.Object.extend('surfsession');
            const query = new Parse.Query(Surfsession);
            query.get(sessionId).then((sessionObject) => {
            sessionObject.set('surf_session_notes', newNotes);
            return sessionObject.save();
            }).then(() => {
            setSessionDetails((prevDetails) => ({
              ...prevDetails,
              notes: newNotes,
            }));
           }).catch((error) => {
            console.error('Error saving notes:', error.message);
            });
          }}
        />

        </div>
        <div className="delete-session">
          <button className="delete-btn" danger onClick={showDeleteConfirm}>Delete Session</button>
        </div>


    </div>
  );
};

export default SessionDetails;
