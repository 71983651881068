import React, { useEffect, useState } from 'react';
import Parse from 'parse/dist/parse.min.js';
import { Table, Spin, Button } from 'antd';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';

export const MyBoards = () => {
    const [surfBoards, setSurfBoards] = useState([]);
    const [isLoadingBoards, setIsLoadingBoards] = useState(false);

    useEffect(() => {
        setIsLoadingBoards(true);
        const fetchSurfBoards = async () => {
            const currentUser = Parse.User.current();
            if (!currentUser) {
                console.log('No user logged in');
                setIsLoadingBoards(false);
                return;
            }

            const SurfBoard = Parse.Object.extend('SurfBoard');
            const boardQuery = new Parse.Query(SurfBoard);
            boardQuery.equalTo('user_board', currentUser);

            try {
                const boards = await boardQuery.find();
                const surfBoardsWithCounts = await Promise.all(boards.map(async (boardItem) => {
                    const Surfsession = Parse.Object.extend('surfsession');
                    const sessionQuery = new Parse.Query(Surfsession);
                    sessionQuery.equalTo('surfBoard', boardItem);
                    sessionQuery.descending('surf_session_date');
                    sessionQuery.limit(1);

                    const count = await sessionQuery.count();
                    const lastSessionResult = await sessionQuery.first();

                    const lastSessionDate = lastSessionResult ? moment(lastSessionResult.get('surf_session_date')).format('MMM D, YYYY') : 'No Sessions';

                    return {
                        key: boardItem.id,
                        boardName: boardItem.get('board_name'),
                        boardNotes: boardItem.get('notes'),
                        volume: boardItem.get('volume'),
                        sessionCount: count,
                        lastSession: lastSessionDate
                    };
                }));
                setSurfBoards(surfBoardsWithCounts);
            } catch (error) {
                console.error('Error fetching surf boards:', error.message);
            } finally {
                setIsLoadingBoards(false);
            }
        };

        fetchSurfBoards();
    }, []);

    const columns = [
        {
            title: 'Board',
            dataIndex: 'boardName',
            key: 'boardName',
            sorter: (a, b) => a.boardName.localeCompare(b.boardName),
            sortDirections: ['descend', 'ascend'],
            width: 200,
        },
        {
            title: 'Session Count',
            dataIndex: 'sessionCount',
            key: 'sessionCount',
            align: 'center',
            sorter: (a, b) => a.sessionCount - b.sessionCount,
            sortDirections: ['descend', 'ascend'],
            width: 120,
        },
        {
            title: 'Last Surfed',
            dataIndex: 'lastSession',
            key: 'lastSession',
            sorter: (a, b) => moment(a.lastSession, 'MMM D, YYYY').unix() - moment(b.lastSession, 'MMM D, YYYY').unix(),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
            width: 150,
        },
        {
            title: 'Notes',
            dataIndex: 'boardNotes',
            key: 'boardNotes',
        },
    ];

    const navigate = useNavigate();

    const onRowClick = (record) => ({
        onClick: () => {
            navigate(`/myboards/${encodeURIComponent(record.boardName)}`);
        },
    });

    return (
        <div className="content">
            <div className="title-card">
                <h2>My Surf Boards</h2>
            </div>
            <div>
            <Link to="/addboard">
                    <Button type="primary" style={{ marginBottom: '20px', marginLeft: '5px' }}>
                        Add New Board
                    </Button>
                </Link>
            </div>
            <Table
                columns={columns}
                dataSource={surfBoards}
                rowClassName={() => 'card-row'}
                onRow={onRowClick}
                loading={{
                    spinning: isLoadingBoards,
                    tip: 'Fetching your surf boards...',
                    indicator: <Spin size="large" />
                }}
                locale={{
                    emptyText: isLoadingBoards ? ' ' : <div>You have no surf boards, <Link to="/addboard">click here</Link> to add your first board.</div>
                }}
            />
        </div>
    );
};
