import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import Parse from 'parse/dist/parse.min.js';
import { AllRoutes } from './AllRoutes';
import { AuthProvider } from './Components/useAuth';
import Footer from './Components/Footer.js';
import Header from './Components/Header.js';
import Feedback from './Components/Feedback.js';
import './GlobalStyles.css';
import './Pages/UserLogin.css';
import ReactGA from 'react-ga4';

// Initialize Parse
const initializeParse = () => {
  const app_id = process.env.REACT_APP_PARSE_APP_ID;
  const host_url = process.env.REACT_APP_PARSE_HOST_URL;
  const javascript_key = process.env.REACT_APP_PARSE_JAVASCRIPT_KEY;

  if (app_id && host_url && javascript_key) {
    Parse.initialize(app_id, javascript_key);
    Parse.serverURL = host_url;
    console.log("Parse initialized successfully with app_id:", app_id);
  } else {
    console.error("Parse initialization failed due to missing environment variables");
  }
};

initializeParse();

// Initialize Google Analytics
ReactGA.initialize('G-5RRSBZPG0Q');

function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
}

const PageTracker = ({ children }) => {
  usePageTracking();
  return <>{children}</>;
};

function App() {
  return (
    <Router>
      <div>
        <AuthProvider>
          <Header />
          <div className="main-content">
            <PageTracker>
              <AllRoutes />
            </PageTracker>
          </div>
          <Footer />
          <Feedback />
        </AuthProvider>
      </div>
    </Router>
  );
}

export default App;
