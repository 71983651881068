import React, { useState } from 'react';

const EditableNotes = ({ initialNotes, onSave, isEditable = true }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState(initialNotes);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    onSave(notes);
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setNotes(initialNotes);
    setIsEditing(false);
  };

  return (
    <div className="notes-card">
      <h3>Notes:</h3>
      <br />
      {isEditing ? (
        <div>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="edit-notes-textarea"
          />
          <br />
          <button className="notes-edit-btn" onClick={handleSaveClick}>Save</button>
          <button className="notes-edit-btn" onClick={handleCancelClick}>Cancel</button>
        </div>
      ) : (
        <div>
          <p>{notes}</p>
          {isEditable && (
            <>
              <br />
              <button className="notes-edit-btn" onClick={handleEditClick}>Edit</button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default EditableNotes;
