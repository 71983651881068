import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Parse from 'parse/dist/parse.min.js';

export const ProtectedRoute = () => {
  // Function to check if the user is logged in
  const isLoggedIn = () => !!Parse.User.current();

  // Render the Outlet for nested routes if authenticated, otherwise redirect to login
  return isLoggedIn() ? <Outlet /> : <Navigate to="/login" replace />;
};
