
import axios from 'axios';
import moment from 'moment';
import { getCompassDirection } from '../Utils';



export const fetchTideData = async (lat, lng, date) => {
    
    const startOfExtendedPeriod = moment(date).subtract(36, 'hours').format('YYYY-MM-DDTHH:mm:ss'); // 2 PM of the previous day
    const endOfExtendedPeriod = moment(date).add(61, 'hours').format('YYYY-MM-DDTHH:mm:ss'); // 2 PM of the next day
    

    try {
      const response = await axios.get(`https://api.stormglass.io/v2/tide/extremes/point`, {
        params: {
          lat: lat,
          lng: lng,
          start: startOfExtendedPeriod,
          end: endOfExtendedPeriod,
        },
        headers: {
          'Authorization': process.env.REACT_APP_STORMGLASS_API_KEY
        }
      });

    // Format the tide data immediately before returning
      const tideData = response.data.data;
      const formattedTideData = [];

      tideData.forEach(item => {
        formattedTideData.push({
          time: moment(item.time).format('YYYY-MM-DD HH:mm:ss'), // Ensure time format is consistent with chart's x-axis parser
          height: item.height.toFixed(2) // Format height as a float
        });
      });
    
      return formattedTideData;
    
     
    } catch (error) {
      console.error('Error fetching tide data:', error);
      return null;
    } 
  };




  export const fetchWeatherData = async (lat, lng, date) => {
    const formattedDate = moment(date).format('YYYY-MM-DDT00:00:00');
    const endDate = moment(date).add(2, 'days').format('YYYY-MM-DDT00:00:00');
    
    try {
      const response = await axios.get(`https://api.stormglass.io/v2/weather/point`, {
        params: {
          lat: lat,
          lng: lng,
          start: formattedDate,
          end: endDate,
          params: 'airTemperature,windSpeed,windDirection,waveHeight,waveDirection,wavePeriod'
        },
        headers: {
          'Authorization': process.env.REACT_APP_STORMGLASS_API_KEY
        }
      });

     

        // Format the data immediately before returning
    const weatherData = response.data.hours; 
    const formattedWeatherData = {
      Time: [],
      AirTemp: [],
      WaveDirection: [],
      WaveDirectionDegrees: [],
      WaveHeight: [],
      WavePeriod: [],
      WindDirection: [],
      WindDirectionDegrees: [],
      WindSpeed: []
    };

    weatherData.forEach(item => {
      formattedWeatherData.Time.push(moment(item.time).format('YYYY-MM-DD HH:mm'));
      formattedWeatherData.AirTemp.push(item.airTemperature.noaa);
      formattedWeatherData.WaveDirection.push(getCompassDirection(item.waveDirection.noaa));
      formattedWeatherData.WaveDirectionDegrees.push(item.waveDirection.noaa);
      formattedWeatherData.WaveHeight.push(item.waveHeight.noaa);
      formattedWeatherData.WavePeriod.push(item.wavePeriod.noaa);
      formattedWeatherData.WindDirection.push(getCompassDirection(item.windDirection.noaa));
      formattedWeatherData.WindDirectionDegrees.push(item.windDirection.noaa);
      formattedWeatherData.WindSpeed.push(item.windSpeed.noaa);
    });

      return formattedWeatherData;  
    } catch (error) {
      console.error('Error fetching weather data:', error);
      return null;
    }
};



export const fetchAstronomyData = async (lat, lng, date) => {
  const formattedDate = moment(date).format('YYYY-MM-DDT00:00:00');
  const endDate = moment(date).add(2, 'days').format('YYYY-MM-DDT00:00:00');

  try {
    const response = await axios.get(`https://api.stormglass.io/v2/astronomy/point`, {
      params: {
        lat: lat,
        lng: lng,
        start: formattedDate,
        end: formattedDate,
        params: 'sunrise, sunset'  
      },
      headers: {
        'Authorization': process.env.REACT_APP_STORMGLASS_API_KEY
      }
    });


    if (response.data && response.data.data && response.data.data.length > 0) {
      const astronomyData = response.data.data[0];
      const originalSunrise = astronomyData.sunrise ? moment(astronomyData.sunrise) : null;
      const originalSunset = astronomyData.sunset ? moment(astronomyData.sunset) : null;

      const roundedSunrise = originalSunrise ? originalSunrise.clone().startOf('hour') : null;
      const roundedSunset = originalSunset ? originalSunset.clone().startOf('hour') : null;

      const formattedAstronomyData = {
        originalSunriseTime: originalSunrise ? originalSunrise.format('HH:mm') : 'N/A',
        originalSunsetTime: originalSunset ? originalSunset.format('HH:mm') : 'N/A',
        roundedSunriseTime: roundedSunrise ? roundedSunrise.format('HH:mm') : 'N/A',
        roundedSunsetTime: roundedSunset ? roundedSunset.format('HH:mm') : 'N/A'
      };

      return formattedAstronomyData;  
    } else {
      console.error('No astronomy data found');
      return null;
    }
  } catch (error) {
    console.error('Error fetching sunrise and sunset data:', error);
    return null;
  }
};