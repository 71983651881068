import React, { useState } from 'react';
import './UserLogin.css';
import '../App.css';
import { Button, Divider, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAuth } from '../Components/useAuth'; // Import useAuth hook

export const UserLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { doUserLogIn } = useAuth(); // Destructure doUserLogIn from useAuth

  const handleLogin = async () => {
    const success = await doUserLogIn(username, password); // Call doUserLogIn and await the result
    if (success) {
      navigate('/newsession'); // Redirect on successful login
    }
  };

  return (
    <div>
      <div className="header">
        <center>
          <h1>{'Surf Journal'}</h1>
          <p>{'Currently in beta. Thank you for your feedback.'}</p>
        </center>
      </div>
      <div className="container">
        <h2 className="heading">{'Surfer Login'}</h2>
        <Divider />
        <div className="form_wrapper">
          <Input
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            placeholder="Username"
            size="large"
            className="form_input"
          />
          <Input
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            placeholder="Password"
            size="large"
            type="password"
            className="form_input"
          />
        </div>
        <div className="form_buttons">
          <Button
            onClick={handleLogin} // Call handleLogin on click
            type="primary"
            className="form_button"
            color={'#208AEC'}
            size="large"
            block
          >
            Log In
          </Button>
        </div>
        <Divider />
        <p className="form__hint">Don't have an account? <Link className="form__link" to="/register">Sign up</Link></p>
      </div>
    </div>
  );
};
