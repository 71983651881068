import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Parse from 'parse/dist/parse.min.js';
import { Table, Spin } from 'antd';
import moment from 'moment';
import { formatMinutesTime } from '../Utils';
import EditableNotes from '../Components/EditableNotes';

const getRatingClass = (rating) => {
  if (rating <= 1) return 'rating-red';
  if (rating <= 2) return 'rating-yellow';
  if (rating <= 3) return 'rating-blue';
  if (rating <= 4) return 'rating-lightgreen';
  return 'rating-darkgreen';
};

const BreakDetails = () => {
  const { breakName } = useParams();
  const [breakDetails, setBreakDetails] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchBreakDetails = async () => {
      const SurfBreak = Parse.Object.extend('SurfBreak');
      const breakQuery = new Parse.Query(SurfBreak);
      breakQuery.equalTo('break_name', decodeURIComponent(breakName));

      try {
        const surfBreak = await breakQuery.first();
        if (surfBreak) {
          setBreakDetails({
            breakId: surfBreak.id,
            breakName: surfBreak.get('break_name'),
            notes: surfBreak.get('notes'),
          });

          const Surfsession = Parse.Object.extend('surfsession');
          const sessionQuery = new Parse.Query(Surfsession);
          sessionQuery.equalTo('surfBreak', surfBreak);
          sessionQuery.descending('surf_session_date');

          const results = await sessionQuery.find();
          const formattedSessions = results.map((session) => ({
            key: session.id,
            date: moment(session.get('surf_session_date')).format('MMM D, YYYY'),
            rating: session.get('surf_session_rating'),
            breakName: session.get('surfBreak').get('break_name'),
            length: session.get('surf_session_duration'),
            notes: session.get('surf_session_notes'),
          }));

          setSessions(formattedSessions);
        }
      } catch (error) {
        console.error('Failed to fetch break details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBreakDetails();
  }, [breakName]);

  const columns = [
    { title: 'Date', dataIndex: 'date', key: 'date', sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(), sortDirections: ['descend', 'ascend'] },
    { title: 'Rating', dataIndex: 'rating', key: 'rating', align: 'center', render: (rating) => (<div className="rating-container"><div className={`rating-bar ${getRatingClass(rating)}`}/>{rating}</div>) },
    { title: 'Break', dataIndex: 'breakName', key: 'breakName' },
    { title: 'Length', dataIndex: 'length', key: 'length', align: 'center', render: (length) => <span className="no-wrap">{formatMinutesTime(length)}</span> },
    { title: 'Notes', dataIndex: 'notes', key: 'notes' },
  ];

  if (!breakDetails) {
    return <div>Loading...</div>;
  }

  const onSaveNotes = async (newNotes) => {
    if (!breakDetails.breakId) return; // Check if breakId is available

    const SurfBreak = Parse.Object.extend('SurfBreak');
    const query = new Parse.Query(SurfBreak);
    try {
      const breakObject = await query.get(breakDetails.breakId);
      breakObject.set('notes', newNotes);
      await breakObject.save();
      setBreakDetails(prevDetails => ({
        ...prevDetails,
        notes: newNotes
      }));
    } catch (error) {
      console.error('Error saving notes:', error.message);
    }
  };


  return (
    <div className="break-details">
      <div className="title-card">
        <h2>{breakDetails.breakName}</h2>
      </div>
      <div>
        <EditableNotes
          initialNotes={breakDetails.notes}
          onSave={onSaveNotes}
        />
      </div>
      <div className="title-card">
      <h2>Logged Sessions at {breakDetails.breakName}</h2>
      </div>
      <div style={{ marginBottom: '70px' }}>
      <Table 
        columns={columns} 
        dataSource={sessions} 
        loading={isLoading}
        rowClassName={() => 'card-row'}
      />
      </div>
    </div>
  );
};

export default BreakDetails;
