import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from 'moment';
import ChartDataLabels from 'chartjs-plugin-datalabels';


const getTimeWithDate = (timeString, dateString) => {
  // Use the correct format string for parsing the date and time
  const formatString = 'MMM DD, YYYY HH:mm';
  const combinedString = `${dateString} ${timeString}`;
  const datetime = moment(combinedString, formatString).toDate();

  
  // Check if the resulting date is valid
  if (isNaN(datetime.getTime())) { // More robust check for invalid date
    console.error('Invalid date:', datetime, 'from', combinedString);
  }
  
  return datetime;
};



const TideChart = ({ tideData, startTime, endTime, date }) => {
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);

  const MSL = 0;

  useEffect(() => {
    Chart.register(annotationPlugin, ChartDataLabels);
    
    if (tideData && chartContainer.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy(); // Destroy the old chart instance if exists
      }

      const startOfPeriod = moment(date).startOf('day').add(2, 'hours').toDate(); // 2 AM of the current date
      const endOfPeriod = moment(date).add(2, 'days').startOf('day').add(2, 'hours').toDate(); // 2 AM of the next day

      const chartConfig = {
        type: 'line',
        data: {
          labels: tideData.map(d => new Date(d.time)),
          datasets: [{
            label: 'Tide (m)',
            data: tideData.map(d => d.height),
            backgroundColor: '#0073AA',
            borderColor: '#0073AA',
            borderWidth: 1,
            tension: 0.4,
            datalabels: {
              align: 'end',
              anchor: 'end',
              offset: 1,
              color: '#0073AA',
              font: {
                weight: 'bold'
              },
              
            }
          }]
        },
        options: {
          scales: {
            x: {
              type: 'time',
              display: false,
              time: {
                parser: 'YYYY-MM-DD HH:mm:ss',
                unit: 'hour',
                displayFormats: {
                  hour: 'ha'
                }
              },
              offset: true,
              ticks: {
                display: false, // This hides the labels on the x-axis
                maxRotation: 90,
                minRotation: 90
              },
              grid: {
                display: false, // Ensures that the grid lines are still displayed
              },
              min: startOfPeriod,
              max: endOfPeriod,
              
            },
            y: {
              beginAtZero: true,
              display: false,
              title: {
                display: false,
                text: 'Height (m)'
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            annotation: {
              annotations: 
              {
                mslLine: {
                  type: 'line',
                  yMin: MSL,
                  yMax: MSL,
                  borderColor: '#000', // Red line for visibility
                  borderWidth: 0.5,
                  borderDash: [6, 6] // Optional: makes the line dashed
                },
             }
            }
          }
        }
      };

      chartInstance.current = new Chart(chartContainer.current, chartConfig);
    }
  }, [tideData, startTime, endTime, date]); // Redraw chart if tideData changes

  return (
    <div style={{ height: '200px', width: '100%', padding: 0, margin: 0 }}>
      <canvas ref={chartContainer} style={{ display: 'block', width: '100%', height: '100%' }}></canvas>
    </div>
  );
};

export default TideChart;
