import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserRegistration } from './Pages/UserRegistration';
import { UserLogin } from './Pages/UserLogin';
import { NotFound } from './Pages/NotFound';
import { LogASession } from './Pages/LogASession';
import { MyBreaks } from './Pages/MyBreaks';
import { ProtectedRoute} from './Components/ProtectedRoute';
import { MySessions } from './Pages/MySessions';
import HomePage from './Pages/HomePage';
import AddSurfBreak from './Pages/AddSurfBreak';
import SessionDetails from './Components/SessionDetails';
import BreakDetails from './Components/BreakDetails'; 
import AddSurfBoard from './Pages/AddSurfBoard';
import { MyBoards } from './Pages/MyBoards';



export const AllRoutes = () =>{
    return (
        
            
            <Routes> 
                <Route element={<ProtectedRoute />}>
                    {/* Only authenticated users can access these routes */}
                    <Route exact path="/newsession" element={<LogASession />} />
                    <Route exact path="/mybreaks" element={<MyBreaks />} />
                    <Route path="/mybreaks/:breakName" element={<BreakDetails />} />
                    <Route exact path="/addbreak" element={<AddSurfBreak />} />
                    <Route exact path="/mysessions" element={<MySessions />} />
                    <Route path="/mysessions/:sessionId" element={<SessionDetails />} />
                    <Route path="/addboard" element={<AddSurfBoard />} />
                    <Route path="/myboards" element={<MyBoards />} />
                </Route>

                {/* Routes outside the ProtectedRoute component are accessible to anyone */}
                <Route exact path="/" element={<HomePage />} />
                <Route path="/login" element={<UserLogin />} />
                <Route exact path="/register" element={<UserRegistration/>}/>
                <Route path="*" element={<NotFound />} />
                
            </Routes>
            
       

    );

}