    import React, { useEffect, useState } from 'react';
    import Parse from 'parse/dist/parse.min.js';
    import { Table, Spin } from 'antd';
    import moment from 'moment';
    import { Link } from 'react-router-dom';
    import { useNavigate } from 'react-router-dom';
    


export const MyBreaks = () => {
    //Set state that will hold the list of breaks
    const [surfBreaks, setSurfBreaks] = useState([]);
    const [isLoadingBreaks, setIsLoadingBreaks] = useState(false);

    
    useEffect(() => {
        setIsLoadingBreaks(true);
        // Function to fetch surfBreaks from Parse
        const fetchSurfBreaks = async () => {
            const currentUser = Parse.User.current(); // Get the currently logged-in user
          if (!currentUser) {
            console.log('No user logged in');
            setIsLoadingBreaks(false);
            return;
          }
    
          const SurfBreak = Parse.Object.extend('SurfBreak');
            const breakQuery = new Parse.Query(SurfBreak);
            breakQuery.equalTo('user', currentUser);
          
         
          try {
            const breaks = await breakQuery.find();
            const surfBreaksWithCounts = await Promise.all(breaks.map(async (breakItem) => {
                const Surfsession = Parse.Object.extend('surfsession');
                const sessionQuery = new Parse.Query(Surfsession);
                sessionQuery.equalTo('surfBreak', breakItem);
                sessionQuery.descending('surf_session_date'); // Order by date descending
                sessionQuery.limit(1); // Only fetch the most recent session

                const count = await sessionQuery.count();
                const lastSessionResult = await sessionQuery.first();

                const lastSessionDate = lastSessionResult ? moment(lastSessionResult.get('surf_session_date')).format('MMM D, YYYY') : 'No Sessions';

                return {
                    key: breakItem.id,
                    breakName: breakItem.get('break_name'),
                    notes: breakItem.get('notes'),
                    sessionCount: count,
                    lastSession: lastSessionDate
                };
            }));
            setSurfBreaks(surfBreaksWithCounts);
          } catch (error) {
            console.error('Error fetching surf breaks:', error.message);
          } finally {
            setIsLoadingBreaks(false);
          }
        };
    
        fetchSurfBreaks(); // Call the fetch function
    }, []); // Empty dependency array means this effect runs once on component mount
  
    //Setup columns for table (AntD Format)
    const columns = [
      {
        title: 'Break',
        dataIndex: 'breakName',
        key: 'break_name',
        sorter: (a, b) => a.breakName.localeCompare(b.breakName),
        sortDirections: ['descend', 'ascend'],
        
      },
      {
        title: 'Session Count',
        dataIndex: 'sessionCount',
        key: 'sessionCount',
        align: 'center',
        sorter: (a, b) => a.sessionCount - b.sessionCount,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Last Surfed',
        dataIndex: 'lastSession',
        key: 'lastSession',
        sorter: (a, b) => moment(a.lastSession, 'MMM D, YYYY').unix() - moment(b.lastSession, 'MMM D, YYYY').unix(),
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'descend',
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        key: 'notes',
      },
    ];

    const navigate = useNavigate();
    
    const onRowClick = (record) => ({
      onClick: () => {
        navigate(`/mybreaks/${encodeURIComponent(record.breakName)}`); 
      },
    });

    return (
      <div className="content">
        <div className="title-card">
          <h2>My Surf Breaks</h2>
        </div>
        <Table 
          columns={columns} 
          dataSource={surfBreaks}
          rowClassName={() => 'card-row'} 
          onRow={onRowClick} 
          loading={{
            spinning: isLoadingBreaks,
            tip: 'Fetching your surf breaks...',
            indicator: <Spin size="large" /> 
          }}
          locale={{
            emptyText: isLoadingBreaks ? ' ' : <div>You have no surf breaks, <Link to="/addbreak">click here</Link> to add your first break.</div>
          }}
        />
      </div>
    );
  
  };
    
