// useAuth.js
import { useState, useEffect, createContext, useContext } from 'react';
import Parse from 'parse/dist/parse.min.js';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(Parse.User.current());

  useEffect(() => {
    const user = Parse.User.current();
    setCurrentUser(user);
  }, []);

  const doUserLogIn = async (username, password) => {
    try {
      const user = await Parse.User.logIn(username, password);
      setCurrentUser(user);
      //alert(`Success! User ${user.get('username')} has successfully signed in!`);
      return true;
    } catch (error) {
      alert(`Error! ${error.message}`);
      return false;
    }
  };

  const doUserLogOut = async () => {
    try {
      await Parse.User.logOut();
      setCurrentUser(null);
      //alert('Success! No user is logged in anymore!');
      return true;
    } catch (error) {
      alert(`Error! ${error.message}`);
      return false;
    }

  };

  const signUp = async (username, password) => {
    try {
      const user = await Parse.User.signUp(username, password);
      setCurrentUser(user);
      return true;
    } catch (error) {
      alert(`Error! ${error.message}`);
      return false;
    }
  };



  return (
    <AuthContext.Provider value={{ currentUser, doUserLogIn, doUserLogOut, signUp }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
