import React from 'react';
import { NewSurfBreak } from '../Components/NewSurfBreak'; 

const AddSurfBreak = () => {
  return (
    <div className="content">
      <div className="title-card">
        <h2>Add New Surf Break</h2>
      </div>

      <NewSurfBreak />
    </div>
  );
};

export default AddSurfBreak;