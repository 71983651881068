import React, { useState } from 'react';
import Parse from 'parse';
import { Form, Input, Button, notification } from 'antd';

const NewBoard = ({ onBoardAdded = () => {} }) => {
    const [name, setName] = useState('');
    const [notes, setNotes] = useState('');

    const handleSubmit = async () => {
        const currentUser = Parse.User.current();
        if (!currentUser) {
            notification.error({ message: 'Error', description: 'No user logged in.' });
            return;
        }

        const Board = Parse.Object.extend('SurfBoard');
        const board = new Board();
        board.set('board_name', name);
        board.set('notes', notes);
        board.set('user_board', currentUser);
        try {
            await board.save();
            notification.success({ message: 'Board Added', description: 'The board has been successfully added.' });
            onBoardAdded(board);
            setName('');
            setNotes('');
        } catch (error) {
            notification.error({ message: 'Error', description: error.message });
        }
    };

    return (
        <Form
            name="newboard"
            layout="vertical"
            onFinish={handleSubmit}
            className="responsive-form"
            style={{
                maxWidth: 600,
                marginLeft: '2%',
                marginRight: '2%',
                marginTop: '2%',
                marginBottom: '50px'
            }}
        >
            <Form.Item>
                <Input placeholder="Board Name" value={name} onChange={(e) => setName(e.target.value)} required />
            </Form.Item>
            <Form.Item>
                <Input.TextArea placeholder="Notes" value={notes} onChange={(e) => setNotes(e.target.value)} required />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" style={{ marginBottom: '50px' }}>
                    Add Board
                </Button>
            </Form.Item>
        </Form>
    );
};

export default NewBoard;
