import React from "react";
import { useState, useEffect } from "react";
import Parse from "parse/dist/parse.min.js";
import '../GlobalStyles.css';
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import { calculateDistance } from "../Utils";
import './LogASession.css'; 



import {
  Alert,
  Button,
  Form,
  Input,
  DatePicker,
  notification,
  Select,
  TimePicker,
  Slider,
  Space,
  Spin
} from "antd";

const { Option } = Select;



const surfTime = {
  0: .5,
  1: 1,
  2: 1.5,
  3: 2,
  4: 3,
};

export const LogASession = () => {
   
//Update background as form is filled in.
 
  const [backgroundImageIndex, setBackgroundImageIndex] = useState(0);
  const images = [
    './images/background-image1.png',
    './images/background-image1.png',
    './images/background-image1.png',
    './images/background-image1.png',
    './images/background-image1.png'
    // Add more as needed
  ];

  const handleFieldChange = () => {
    setBackgroundImageIndex(prevIndex => (prevIndex + 1) % images.length);
  };
  

  const timeFormat = 'HH:mm';
  
  // State variables for each form field
  const [surfBreakOptions, setSurfBreakOptions] = useState([]);
  const [selectedSurfBreakId, setSelectedSurfBreakId] = useState(null);
  const [surfBoardOptions, setSurfBoardOptions] = useState([]);
  const [selectedSurfBoardId, setSelectedSurfBoardId] = useState(null);
  const [date, setDate] = useState(null); // Stores the selected date as a moment object
  const [startTime, setStartTime] = useState(null); // Stores the selected time as a moment object
  const [endTime, setEndTime] = useState(null);
  const [notes, setNotes] = useState('');
  const [rating, setRating] = useState(3);
  const [isLoadingBreaks, setIsLoadingBreaks] = useState(false);
  const [isLoadingBoards, setIsLoadingBoards] = useState(false);

  //Rating labels for slider
  const marks = {
    1: 'Terrible',
    2: 'Not Great',
    3: 'Fun',
    4: 'Super Fun',
    5: 'Epic'
  };

  //Calculate surf session duration
  const calculateDurationInMinutes = (startTime, endTime) => {
    if (!startTime || !endTime) {
      console.error("Start time or end time is missing.");
      return 0; // Return 0 if either time is missing to avoid errors
    }

    // Calculate the duration using moment
    const duration = moment.duration(endTime.diff(startTime));
    return duration.asMinutes(); // Returns the duration in minutes
  };


  //Fetch surf breaks and surf boards for current logged in user
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        fetchSurfBreaks(latitude, longitude);
        fetchSurfBoards();
      },
      err => {
        console.error('Error getting location', err);
        fetchSurfBreaks(); // Call without coordinates to sort by most recent
        fetchSurfBoards();
      }
    );
  }, []);
  

    
  
    const fetchSurfBreaks = async (latitude = null, longitude = null) => {
      setIsLoadingBreaks(true);
      const currentUser = Parse.User.current();
      if (!currentUser) {
        setIsLoadingBreaks(false);
        return;
      }
    
      const SurfBreak = Parse.Object.extend('SurfBreak');
      const query = new Parse.Query(SurfBreak);
      query.equalTo('user', currentUser);
    
      if (latitude && longitude) {
        // When coordinates are available, calculate distance
        query.find().then(results => {
          const options = results.map(surfBreak => {
            const geoPoint = surfBreak.get('location');
            return {
              value: surfBreak.id,
              label: surfBreak.get('break_name'),
              lat: geoPoint ? geoPoint.latitude : null,
              lng: geoPoint ? geoPoint.longitude : null,
            };
          }).sort((a, b) => {
            const distanceA = calculateDistance(latitude, longitude, a.lat, a.lng);
            const distanceB = calculateDistance(latitude, longitude, b.lat, b.lng);
            return distanceA - distanceB;
          });
    
          prependAddNewBreakOption(options);
        });
      } else {
        // When no coordinates, sort by the most recent
        query.descending('createdAt'); // or 'updatedAt' based on your needs
        query.find().then(results => {
          const options = results.map(surfBreak => ({
            value: surfBreak.id,
            label: surfBreak.get('break_name')
          }));
    
          prependAddNewBreakOption(options);
        });
      }
    };
    
    const prependAddNewBreakOption = (options) => {
      const addNewBreakOption = { value: 'new-break', label: 'Add New Break' };
      options.unshift(addNewBreakOption);
      setSurfBreakOptions(options);
      setIsLoadingBreaks(false);
    };
    

//Fetch Surfboards for Current User

    const fetchSurfBoards = async () => {
      setIsLoadingBoards(true);
      const currentUser = Parse.User.current();
      if (!currentUser) {
        setIsLoadingBoards(false);
        return;
      }
  
      const SurfBoard = Parse.Object.extend('SurfBoard');
      const query = new Parse.Query(SurfBoard);
      query.equalTo('user_board', currentUser);
  
      query.find().then(results => {
        const options = results.map(surfBoard => ({
          value: surfBoard.id,
          label: surfBoard.get('board_name')
        }));
  
        prependAddNewBoardOption(options);
      });
    };
  
    const prependAddNewBoardOption = (options) => {
      const addNewBoardOption = { value: 'new-board', label: 'Add New Board' };
      options.unshift(addNewBoardOption);
      setSurfBoardOptions(options);
      setIsLoadingBoards(false);
    };
   
  


  // Form submission handler
  const addNewSession = async () => {
    
    let sessionStartDateTime = null;
    if (date && startTime) {
      // Clone the date object to avoid mutations, then set the time
      sessionStartDateTime = date.clone()
      .hour(startTime.get('hour'))
      .minute(startTime.get('minute'))
      .second(startTime.get('second'));
    }
    
    let sessionEndDateTime = null;
    if (date && endTime) {
      // Clone the date object to avoid mutations, then set the time
      sessionEndDateTime = date.clone()
      .hour(endTime.get('hour'))
      .minute(endTime.get('minute'))
      .second(endTime.get('second'));
    }

    const durationInMinutes = calculateDurationInMinutes(sessionStartDateTime, sessionEndDateTime);


    // Get current user
    const currentUser = Parse.User.current();
    // Get username of the current user (ensure there's a logged-in user)
    const username = currentUser ? currentUser.get('username') : null;
   
    const SurfBreak = Parse.Object.extend('SurfBreak');
    const surfBreakPointer = SurfBreak.createWithoutData(selectedSurfBreakId);

    const SurfBoard = Parse.Object.extend('SurfBoard');
    const surfBoardPointer = SurfBoard.createWithoutData(selectedSurfBoardId);


    // Create a new Parse object and set its fields based on the state
    let session = new Parse.Object('surfsession'); // 'surfsession' is the Parse class
      session.set('surfBreak', surfBreakPointer);
      session.set('surfBoard', surfBoardPointer);
      session.set('surf_session_date', sessionStartDateTime ? sessionStartDateTime.toDate() : null); // Convert moment to JS Date
      session.set('surf_session_date_end', sessionEndDateTime ? sessionEndDateTime.toDate() : null);
      session.set('surf_session_duration', durationInMinutes);
      session.set('surf_session_rating', rating);
      session.set('surf_session_notes', notes);

    // Set the username of the current user who submits the form
    if (username) {
      session.set('user', Parse.User.current());
      } else {
      console.log('No logged-in user found');
    }

    try {
      await session.save();
      notification.success({
        message: 'Success!',
        description: 'Session Saved',
      });
      navigate(`/mysessions/${session.id}`);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
      });
    };

  };

  // Handlers for form fields
  //const onSurfBreakNameChange = (e) => setSelectedSurfBreakId(e.target.value);
  const navigate = useNavigate();
  const onSurfBreakChange = (value) => {
    if (value === 'new-break') {
      navigate('/addbreak');
    } else {
      setSelectedSurfBreakId(value);
    }
  };

  const onSurfBoardChange = (value) => {
    if (value === 'new-board') {
      navigate('/addboard');
    } else {
      setSelectedSurfBoardId(value);
    }
  };


  const onDateChange = (date) => setDate(date); // Save the moment date object
  const onFinishStartTimeChange = (time) => setStartTime(time); // Save the moment time object
  const onFinishEndTimeChange = (time) => setEndTime(time);
  const onRatingChange = (rating) => setRating(rating);
  //const onSurfDurationChange = (value) => setSurfDuration(value);
  const onNotesChange = (e) => setNotes(e.target.value);

  // Handler for form submission
  const onFinish = () => {
    
    addNewSession();
  };

  
  console.log(surfBreakOptions);


return (
<> 
<div className="background-image-container" style={{ backgroundImage: `url(${images[backgroundImageIndex]})` }}>
</div>
<div className="form-container">
    <div className="page-title">
      <h2>Log A Session</h2>
    </div>

  <Form
    name="logSession"
    onFinish={onFinish}
    className="responsive-form"
    style={{
      maxWidth: 600,
      marginLeft: '2%',
      marginRight: '2%',
      marginTop: '2%',
      marginBottom: '2%'
    }}
    layout="vertical"
    autoComplete="off"
    >
    <Form.Item name="breakname" label="Surf Break">
      <Select
        showSearch
        placeholder="Select a surf break"
        notFoundContent={isLoadingBreaks ? <Spin size="small" tip="Fetching your breaks..."/> : "Wait for it..."}
        onChange={(value) => { onSurfBreakChange(value); handleFieldChange(); }}
        loading={isLoadingBreaks}
        filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
      {surfBreakOptions.map(option => (
        <Option key={option.value} value={option.value}>{option.label}</Option>
      ))}
    </Select>
    </Form.Item>

    <Form.Item name="boardname" label="Surf Board">
            <Select
              showSearch
              placeholder="Select a surf board"
              notFoundContent={isLoadingBoards ? <Spin size="small" tip="Fetching your boards..." /> : "Wait for it..."}
              onChange={(value) => { onSurfBoardChange(value); handleFieldChange(); }}
              loading={isLoadingBoards}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {surfBoardOptions.map(option => (
                <Option key={option.value} value={option.value}>{option.label}</Option>
              ))}
            </Select>
          </Form.Item>

    <Form.Item>
      <DatePicker onChange={(date) => { onDateChange(date); handleFieldChange(); }} />
    </Form.Item>

    <Form.Item>
      <TimePicker 
        placeholder="Start time" 
        onChange={(time) => { onFinishStartTimeChange(time); handleFieldChange(); }} 
        format={timeFormat}
        minuteStep={15}
        defaultOpenValue={moment().hour(4).minute(0).second(0)}
      />
    </Form.Item>

    <Form.Item>
      <TimePicker 
        placeholder="End time" 
        onChange={(time) => { onFinishEndTimeChange(time); handleFieldChange(); }} 
        format={timeFormat}
        minuteStep={15}
        defaultOpenValue={moment().hour(8).minute(0).second(0)}
      />
    </Form.Item>

    <Form.Item label="Session Rating" className="session-rating-selector"> 
          <Slider
            marks={marks}
            step={1}
            min={1}
            max={5}
            onChange={(newRating) => { onRatingChange(newRating); handleFieldChange(); }}
            value={rating}
          />
        </Form.Item>

    <Form.Item>
      <Input.TextArea placeholder="Notes" value={notes} onChange={onNotesChange} />
    </Form.Item>

    <Form.Item className="button-container">
      <Button className="log-it-button" type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  
  </Form>
</div>
</>
);
};
