import React, { useState } from 'react';
import Parse from 'parse/dist/parse.min.js';
import '../App.css';
import './UserRegistration.css';
import { Button, Divider, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import surfjournallogo from '../images/surfjournallogo.png';
import { useAuth } from '../Components/useAuth';


export const UserRegistration = () => {
  const navigate = useNavigate();
  const { signUp } = useAuth();
  
  // State variables
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  

  
  const doUserRegistration = async function () {
    const success = await signUp(username, password); // Use the signUp function from the context
    if (success) {
      setSuccessMessage('Welcome!');
      setTimeout(() => {
        navigate('/newsession');
      }, 2000);
    }
  };

  return (
    <div>
      <div className="header">
        <center>
        <img
          className="header_logo"
          alt="Surf Journal Logo"
          src={
            surfjournallogo
          }
        />
        <p className="header_text_bold">{'Surf Journal'}</p>
        <p className="header_text">{'Register to start logging sessions'}</p>
        </center>
      </div>
      <div className="container">
        <h2 className="heading">{'Surfer Registration'}</h2>
        <Divider />
        <div className="form_wrapper">
          <Input
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            placeholder="Username"
            size="large"
            className="form_input"
          />
          <Input
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            placeholder="Password"
            size="large"
            type="password"
            className="form_input"
          />
        </div>
        <div className="form_buttons">
          <Button
            onClick={() => doUserRegistration()}
            type="primary"
            className="form_button"
            color={'#208AEC'}
            size="large"
          >
            Register
          </Button>
        </div>
        {successMessage && (
          <div className="success_message">
            <p>{successMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
};
