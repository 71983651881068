import React from 'react';
import './HomePage.css'; 
import surfjournalcaptures from '../images/surfjournalcaptures.png';
import today from '../images/today.png';
import tmrw from '../images/tmrw.png' 
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import Subscribe from '../Components/Subscribe';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const faqData = [
  {
    question: 'How much does it cost?',
    answer: 'All we want from you right now is your feedback. Please report any bugs and share any feature requests. All early adopters who provide feedback will get Surf Journal free for life.'
  },
  {
    question: 'How do I provide my feedback?',
    answer: 'Use the red bottun in the bottom right.'
  },
  {
    question: 'When will Surf Journal be ready for iOS and Android?',
    answer: 'We expect to launch sometime in 2024. For updates on new platforms and features please subscribe below.'
  },
];


const HomePage = () => {
 
return (
    <div className="homepage">
      <Header />
      <HeroSection />
      <Explainer />
      <CoreBenefits />
      <FAQ />
      <CallToAction />
    </div>
  );
};

const Header = () => (
  <header className="header">
    <h3>Beta Version</h3>
    <p>Thank you for your feedback</p>
  </header>
);

const HeroSection = () => (
  <section className="hero-section">
    <img src={surfjournalcaptures} alt="Digital Surf Journal" className="hero-image" />
    <div className="hero-text-content">
      <h1>Surf Journal</h1>
      <div className="hero-section-description">
        <h3>Session tracking & forecasting</h3>
        <p>Designed as a companion for surfers who keep notes manually.</p>
      </div>
      <div className="hero-section-button">
      <Link to="/register">
          <button className="primary-btn">Register</button>
        </Link>
        <Link to="/newsession">
          <button className="secondary-btn">Log A Session</button>
        </Link>
      </div>
    </div>
  </section>
);

const Explainer = () => (
  <section className="explainer">
    <div className="explainer-container">
      <div className="explainer-video">
        <ReactPlayer
          url="https://youtu.be/uXr-0j90UCQ"
          controls={true}
          width="100%"
          height="auto"
        />
      </div>
      <div className="explainer-text">
        <h2>How it works</h2>
        <p>Log your surf sessions.</p>
        <p>Evaluate conditions.</p>
        <p>Keep notes.</p>
      </div>
    </div>
  </section>
);

const CoreBenefits = () => (
  <section className="core-benefits">
    <div className="benefits">
      <div className="benefit benefit-left">
        <img src={today} alt="Surf Journal Evaluation of Surf Session" />
        <div className="text">
            <h2>Why was it good today?</h2>
            <p>Specific swell, tides, and wind direction.</p>
        </div>
      </div>
      <div className="benefit benefit-right">
        <img src={tmrw} alt="Surf Journal - Will Tomorrow's Surf Be Like Today?" />
        <div className="text">
            <h2>Will it be good again tomorrow?</h2>
            <p>See how tomorrow's forecast compares to today.</p>
        </div>
      </div>
      <div className="benefit benefit-left">
        <img src={tmrw} alt="Surf Forecast for Next Week" />
        <div className="text">
            <h2>How's next week looking?</h2>
            <p>Forecast conditions for the next 7 days.</p>
        </div>
      </div>
    </div>
  </section>
);

const FAQ = () => (
  <section className="faq-section">
    <h2>More questions...</h2>
    <Collapse accordion>
      {faqData.map((faq, index) => (
        <Panel header={faq.question} key={index}>
          <p>{faq.answer}</p>
        </Panel>
      ))}
    </Collapse>
  </section>
);

const CallToAction = () => (
  <section className="call-to-action">
    <h3>Want Updates?</h3>
    <p>Get notified when new features are launched.</p>
    <Subscribe />
  </section>
);

export default HomePage;
